/**
 * 节点code码
 * @type {*[]}
 */
export const NODE_NAME = {
    NODE_START:'NODE_START', //开始
    NODE_END:'NODE_END', //结束
    NODE_PHOTO:'NODE_PHOTO', //拍照
    NODE_VOICE_INPUT:'NODE_VOICE_INPUT', //语音输入
    NODE_AI_IDENTIFY:'NODE_AI_IDENTIFY', //AI识别
    NODE_ENVIRONMENTAL_MONITORING:'NODE_ENVIRONMENTAL_MONITORING', //环境监测
    NODE_DEVICE_STATUS:'NODE_DEVICE_STATUS', //设备状态
    NODE_TEXT:'NODE_TEXT', //文本
    NODE_IMAGE:'NODE_IMAGE', //图片
    NODE_BATCH_OPERATION:'NODE_BATCH_OPERATION', //批量操作
    NODE_BATCH_INPUT:'NODE_BATCH_INPUT', //批量输入
}

/**
 * 固定按钮code码
 * @type {{}}
 */
export const DEFAULT_BUTTON_NAME = {
    TRIGGER_PHOTO:'TRIGGER_PHOTO', //拍照
    TAKE_NEW_PHOTO:'TAKE_NEW_PHOTO', //重新拍照
    NEXT_STEP:'NEXT_STEP', //下一步
    LOOK_IMAGE:'LOOK_IMAGE', //查看图片
    START_RECORDING:'START_RECORDING', //开始录音
    RECORDING_AGAIN:'RECORDING_AGAIN', //重新录音
    PLAY_RECORDING:'PLAY_RECORDING', //播放录音
    STOP_PLAY_RECORDING:'STOP_PLAY_RECORDING', //停止播放录音
    SELECTED_DEFAULT:'SELECTED_DEFAULT', //选择默认
    CONFIRM:'CONFIRM', //确定
    CANCELLATION:'CANCELLATION', //取消
}

/**
 * 触发字典名称
 * @type {{}}
 */
export const TRIGGER_NAME = {
    TRIGGER_PHOTO:'TRIGGER_PHOTO', //拍照
    TRIGGER_VIDEO:'TRIGGER_VIDEO', //视频
    TRIGGER_AUDIO:'TRIGGER_AUDIO', //音频/语音
}

/**
 * 工作任务列表显示字段
 * @type {{Array}
 */
export const taskListColumns = [
    {
        title: '任务编号',
        key:'tasksCode',
        width:90
    },
    {
        title: '任务名称',
        slot:'任务名称',
    },
    {
        title: '引用流程',
        slot:'引用流程',
    },
    {
        title: '发布人',
        slot:'发布人',
    },
    {
        title: '审批人',
        slot:'审批人',
    },
    {
        title: '状态',
        width:80,
        slot:'状态',
    },
    {
        title: '执行人',
        slot:'执行人',
    },
    {
        title: '创建时间',
        width:140,
        slot:'创建时间',
    },
    {
        width:140,
        title: '操作',
        slot:'操作',
        align: 'center',
    },
]

/**
 * 执行记录列表显示字段
 * @type {{Array}
 */
export const recordListColumns = [
    {
        title: '记录编号',
        key:'recordCode',
    },
    {
        title: '任务名称',
        key:'tasksName',
    },
    {
        title: '状态',
        slot:'状态',
        width:100,
    },
    {
        title: '执行人',
        slot:'执行人',
    },
    {
        title: '开始时间',
        slot:'开始时间',
    },
    {
        title: '完成时间',
        slot:'完成时间',
    },
    {
        title: '操作',
        slot:'操作',
        width:130,
        align: 'center',
    },
]

/**
 * 执行记录详情显示字段
 * @type {{Array}
 */
export const recordInfoColumns = [
    {
        title: '序号',
        slot:'序号',
        align: 'center',
        width:60,
    },
    {
        title: '步骤名称',
        key:'nodeName',
    },
    {
        title: '步骤内容',
        slot:'步骤内容',
    },
    {
        title: '耗时',
        slot:'耗时',
    },
    {
        title: '用户操作',
        slot:'用户操作',
    },
    {
        title: '反馈操作',
        slot:'反馈操作',
        width:160,
        align: 'center',
    },
]

/**
 * 批量操作记录列表显示字段
 * @type {{}[]}
 */
export const recordModalListColumns = [
    {
        title: '序号',
        slot:'序号',
        align: 'center',
        width:60,
    },
    {
        title: '*作业内容',
        key:'batchNodeName',
    },
    {
        title: '*作业标准',
        key:'textDetail',
        width: 300
    },
    {
        title: '图片',
        slot:'图片',
        align: 'center'
    },
    {
        title: '执行操作',
        slot:'执行操作',
    },
    {
        title: '操作',
        slot:'操作',
        width:100,
        align: 'center',
    },
]

/**
 * 批量输入记录显示字段
 * @type {*[]}
 */
export const batchInputRecordColumns = [
    {
        title: '序号',
        slot:'序号',
        align: 'center',
        width:60,
    },
    {
        title: '*作业内容',
        align: 'center',
        key:'batchNodeName',
        width: 260,
    },
    {
        title: '*作业标准',
        key:'textDetail',
        align: 'center',
        width: 260,
    },
    {
        title: '*节点操作',
        slot:'节点操作',
        align: 'center',
        width: 260,
    },
]

/**
 * 任务状态
 * @type {({})[]}
 */
export const taskStatus = [
    {
        label:'未发布',
        value:0
    },
    {
        label:'已发布',
        value:1
    }
]

/**
 * 流程时间分类
 * @type {({})[]}
 */
export const processDate = [
    {
        label:'更新时间',
        value:0
    },
    {
        label:'创建时间',
        value:1
    }
]

/**
 * 流程状态
 * @type {({})[]}
 */
export const processStatus = [
    {
        label:'未发布',
        value:0
    },
    {
        label:'已发布',
        value:1
    }
]

/**
 * 执行状态
 * @type {({})[]}
 */
export const recordStatus = [
    {
        label:'未完成',
        value:0
    },
    {
        label:'已完成',
        value:1
    }
]

/**
 * 工作流菜单
 * @type {({}]})[]}
 */
export const configList = [
    {
        name:'事件',
        visible:true,
        childNode:[
            {
                icon:'icon start',
                name:'开始',
                nodeCode:NODE_NAME.NODE_START,
                width:100,
                background:'#2d8cf0',
                borderColor:'rgba(255,255,255,.6)',
                color:'#fff'
            },
            {
                icon:'icon end',
                name:'结束',
                nodeCode:NODE_NAME.NODE_END,
                width:100,
                background:'#fad05e',
                borderColor:'rgba(255,255,255,.6)',
                color:'#fff'
            }
        ]
    },
    {
        name:'动作',
        visible:true,
        childNode:[
            {
                icon:'icon camera',
                name:'拍照',
                nodeCode:NODE_NAME.NODE_PHOTO,
                width:120,
                background:'#fff',
                borderColor:'#2d8cf0',
                color:'#515a6e'
            },
            {
                icon:'icon voice',
                name:'语音输入',
                nodeCode:NODE_NAME.NODE_VOICE_INPUT,
                width:130,
                background:'#fcce51',
                borderColor:'#2d8cf0',
                color:'#515a6e'
            },
            {
                icon:'icon ai-identify',
                name:'AI识别',
                nodeCode:NODE_NAME.NODE_AI_IDENTIFY,
                width:130,
                background:'#fcce51',
                borderColor:'#2d8cf0',
                color:'#515a6e'
            },
            {
                icon:'icon environmental-monitoring',
                name:'环境监测',
                nodeCode:NODE_NAME.NODE_ENVIRONMENTAL_MONITORING,
                width:130,
                background:'#fcce51',
                borderColor:'#2d8cf0',
                color:'#515a6e'
            },
            {
                icon:'icon device-status',
                name:'设备状态',
                nodeCode:NODE_NAME.NODE_DEVICE_STATUS,
                width:130,
                background:'#fcce51',
                borderColor:'#2d8cf0',
                color:'#515a6e'
            },
        ]
    },
    {
        name:'基础元素',
        visible:true,
        childNode:[
            {
                icon:'icon text',
                name:'文本',
                nodeCode:NODE_NAME.NODE_TEXT,
                width:120,
                background:'#fff',
                borderColor:'#2d8cf0',
                color:'#515a6e'
            },
            {
                icon:'icon image',
                name:'图片',
                nodeCode:NODE_NAME.NODE_IMAGE,
                width:120,
                background:'#fff',
                borderColor:'#2d8cf0',
                color:'#515a6e'
            }
        ]
    },
    {
        name:'流程模版',
        visible:false,
        childNode:[]
    },
]

/**
 * 颜色列表
 * @type {{}}
 */
export const colorList = [
    {
        id:1,
        name:'yellow',
        color:'#eccf3e'
    },
    {
        id:2,
        name:'blue',
        color:'#2d8cf0'
    },
    {
        id:3,
        name:'green',
        color:'#57c22d'
    },
    {
        id:4,
        name:'volcano',
        color:'#fd6a55'
    },
    {
        id:5,
        name:'purple',
        color:'#7237ce'
    }
]

/**
 * 按钮文字
 * @type {*[]}
 */
export const btnText = [
    {
        id:1,
        btnName:'正常',
    },
    {
        id:2,
        btnName:'异常',
    },
    {
        id:3,
        btnName:'确定',
    },
    {
        id:4,
        btnName:'取消',
    },
    {
        id:5,
        btnName:'下一步',
    }
]

/**
 * 图片展示方式
 * @type {*[]}
 */
export const imageReveal = [
    {
        id:1,
        attrKey:'cover',
        name:'充满屏幕'
    },
    {
        id:2,
        attrKey:'contain',
        name:'适合于屏幕'
    },
    {
        id:3,
        attrKey:'stretching',
        name:'拉伸以充满屏幕'
    },
    {
        id:4,
        attrKey:'center',
        name:'居中'
    },
    {
        id:5,
        attrKey:'repeat',
        name:'平铺'
    }
]

/**
 * 触发数据
 * @type {*[]}
 */
export const triggerList = [
    {
        label:'拍照',
        value:TRIGGER_NAME.TRIGGER_PHOTO
    },
    {
        label:'视频',
        value:TRIGGER_NAME.TRIGGER_VIDEO
    }
]

/**
 * 输入类型
 * @type {*[]}
 */
export const inputType = [
    {
        id:1,
        typeName:'文本',
        typeCode:'text',

    },
    {
        id:2,
        typeName:'数字',
        typeCode:'number',
    },
]

/**
 * 结束设置类型
 * @type {*[]}
 */
export const endSettingsType = [
    {
        id:1,
        typeName:'弹窗确认',
        typeCode:'popupConfirmation',
    },
    {
        id:2,
        typeName:'定时提交',
        typeCode:'scheduledSubmission',
    },
    {
        id:3,
        typeName:'自动提交',
        typeCode:'autoCommit',
    },
]

/**
 * 播报类型
 * @type {*[]}
 */
export const announcementsType = [
    {
        id:1,
        typeName:'步骤名称',
        typeCode:'nodeName',
    },
    {
        id:2,
        typeName:'步骤名称+作业内容',
        typeCode:'nodeNameNodeContent',
    },
    {
        id:3,
        typeName:'自定义内容',
        typeCode:'customContent',
    },
]

/**
 * 单位
 * @type {*[]}
 */
export const unitType = [
    {
        id:1,
        unitType:'米',
    },
    {
        id:2,
        unitType:'毫米',
    },
    {
        id:3,
        unitType:'%',
    },
    {
        id:4,
        unitType:'℃',
    },
    {
        id:5,
        unitType:'MPa',
    },
]

/**
 * 工作流菜单
 * @type {*[]}
 */
export const workflowListArray = [
    {
        id: 1,
        name:'作业任务',
        children:[
            {
                id:101,
                name:'作业任务',
                url:'/workflow/task/inspection/list',
                info:'/workflow/task/inspection/info',
                icon:'task-inspection',
            },
            {
                id:102,
                name:'作业记录',
                url:'/workflow/record/inspection/list',
                info:'/workflow/record/inspection/info',
                icon:'record-inspection',
            }
        ],
        visible:true,
    },
    // {
    //     id: 2,
    //     name:'作业任务',
    //     children:[
    //         {
    //             id:201,
    //             name:'作业任务',
    //             url:'/workflow/task/guidance/list',
    //             info:'/workflow/task/guidance/info',
    //             icon:'task-guidance',
    //         },
    //         {
    //             id:202,
    //             name:'作业记录',
    //             url:'/workflow/record/guidance/list',
    //             info:'/workflow/record/guidance/info',
    //             icon:'record-guidance',
    //         },
    //     ],
    //     visible:true,
    // }
]
/**
 * 背景设置类型
 * @type {*[]}
 */
export const backgroundType = [
    {
        id:1,
        typeName:'默认',
        typeCode:'default',
    },
    {
        id:2,
        typeName:'相机背景',
        typeCode:'cameraBackground',
    },
    {
        id:3,
        typeName:'科幻背景',
        typeCode:'scienceFictionBackground',
    },
]

/**
 * 快捷反馈
 * @type {*[]}
 */
export const quickFeedbackArray = [
    {
        id:1,
        typeName:'拍照',
        typeCode:'photograph',
    },
    // {
    //     id:2,
    //     typeName:'录像',
    //     typeCode:'pictureRecording',
    // },
    {
        id:3,
        typeName:'录音',
        typeCode:'soundRecording',
    }
]
